import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./pages/Dashboard/dashboard.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { MSafeWalletAdapter } from "@msafe/aptos-wallet-adapter";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { PontemWallet } from "@pontem/wallet-adapter-plugin";
import { RiseWallet } from "@rise-wallet/wallet-adapter";

const wallets = [
  new PetraWallet(),
  new PontemWallet(),
  new MSafeWalletAdapter(),
  new RiseWallet(),
  new MartianWallet(),
];
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AptosWalletAdapterProvider autoConnect={true} plugins={wallets}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AptosWalletAdapterProvider>
  </React.StrictMode>
);
