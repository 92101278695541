import React, { useState } from "react";
import "./App.css";
import Footer from "./components/PageLayout/Footer";
import Header from "./components/PageLayout/Header";
import { ChainName } from "./context/chainName";
// import { WalletProvider as SuiWalletProvider} from "@suiet/wallet-kit";
// import { SupportChains, DefaultWallets } from "./utils/suiConfigs";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material";
// import "@suiet/wallet-kit/style.css";
import AddressBook from "./pages/AddressBook";
import { darkTheme } from "./style/theme";
import Dashboard from "./pages/Dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import Stream from "./pages/Stream";
import { WalletAdapter } from "./context/WalletAdapter";
import { NetworkAdapter } from "./data/account";
import NewStream from "./pages/NewStream";
import useCurrentPage from "./hooks/useCurrentPage";

export const router = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/stream",
    element: <Stream />,
  },
  {
    path: "/address_book",
    element: <AddressBook />,
  },
  {
    path: "/new_stream",
    element: <NewStream />,
  },
];

function App() {
  const [chainName, setChainName] = useState<string>("aptos");
  const [walletAdapter, setWalletAdapter] = useState<NetworkAdapter>();
  const [currentPageName, setCurrentPage] = useCurrentPage();

  return (
    <ThemeProvider theme={darkTheme}>
      <ChainName.Provider value={{ chainName, setChainName }}>
        <WalletAdapter.Provider value={{ walletAdapter, setWalletAdapter }}>
          <div className="banner">
            <p className="banner-text marquee">
              We are rolling out product migration from v1 to v2. Please visit
              our V2{" "}
              <a
                href="https://app.moveflow.xyz/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#f231f0] hover:underline"
              >
                https://app.moveflow.xyz/ {""}
              </a>{" "}
              to create new streams.
            </p>
          </div>
          <div className="container">
            {
              // chainName === "sui" ?
              //   <SuiWalletProvider chains={SupportChains} defaultWallets={DefaultWallets}>
              //     <Stack spacing={2}>
              //       <Box>
              //         <Header />
              //       </Box>

              //       <Box>
              //         <Footer />
              //       </Box>
              //     </Stack>
              //   </SuiWalletProvider> :

              <Stack spacing={2} direction="column">
                <Box>
                  <Header
                    currentPageName={currentPageName}
                    setCurrentPage={setCurrentPage}
                  ></Header>
                </Box>
                <Box>
                  <Box>
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/stream" element={<Stream />} />
                      <Route
                        path="/address_book"
                        element={
                          <AddressBook
                            currentPageName={currentPageName}
                            setCurrentPage={setCurrentPage}
                          />
                        }
                      />
                      <Route path="/new_stream" element={<NewStream />} />
                      <Route
                        path="/"
                        element={<Navigate to="/dashboard" replace />}
                      />
                    </Routes>
                  </Box>
                </Box>
                <Box>
                  <Footer></Footer>
                </Box>
              </Stack>
            }
          </div>
        </WalletAdapter.Provider>
      </ChainName.Provider>
    </ThemeProvider>
  );
}

export default App;
